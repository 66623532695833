import React, { useState, useEffect } from 'react';
import DrEvent from "../../display/DrEvent";


function ReactionChallenge(props) {
  const [startInterval, setStartInterval] = useState(null);
  const [endInterval, setEndInterval] = useState(null);
  const [capturingTime, setCapturingTime] = useState(false);
  const [dq, setDq] = useState(false);
  let timer;
  useEffect(() => {
    const personalTimeout = (Math.random() * 5000)+3000;
    timer = setTimeout(() => {
      setCapturingTime(true);
      setStartInterval(Date.now());
    }, personalTimeout);
    return () => clearTimeout(timer);
  }, []);

  function handleEarlyClick(e){
    e.preventDefault();
    setDq(true);
    let payload = {};
    payload['userInput'] = 10000;
    payload['type'] = "input submitted"
    props.sendEvent("input submitted", payload)
    clearInterval(timer)
    props.handleNextBtn(e)
  }
  
function handleBtnClicked(e){
    e.preventDefault();
    if(!capturingTime){
        return
    }
    const endTime = Date.now();
    setEndInterval(endTime);
    setCapturingTime(false)
    const res = endTime - startInterval;
    let payload = {};
    payload['userInput'] = res/1000;
    payload['type'] = "input submitted"
    payload['displayType'] = "DESC"
    props.sendEvent("input submitted", payload)   
    props.handleNextBtn(e)
}

  function renderButton(){
    if(dq){
        return <button style={{backgroundColor:"BLACK"}}className='reactionBtn'>Too Early!</button>
    }
    if(endInterval){
        return <button  className='reactionBtn'>Waiting for everyone...</button>
    } else if(capturingTime && !endInterval){
        return <button className='reactionBtn' style={{backgroundColor: "#FF3333"}} onClick={handleBtnClicked}>TAP!</button>
    } else {
        return <button onClick={handleEarlyClick}className='reactionBtn'>Tap When Red</button>
    }
  }

  return (
    <DrEvent className="challengeContainer"
    style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>
      {renderButton()}
    </DrEvent>
  );
}

export default ReactionChallenge;
