import React, { useState, useEffect, useRef } from 'react';

const colorOne = {
    "bodyguard" : "#33FFFF",
    "medusa": "#66cc66",
    "drinks doubled": "#FF6699",
    "question master": "#FFCCFF",
    "no pinky on drinky": "#FF6699",
    "floor is lava": "#FFFF66",
    "marco polo" : "#33FFFF"
}

const colorTwo = {
    "bodyguard": "#99CCFF",
    "medusa": "#99FFCC",
    "drinks doubled": "#FF3333",
    "question master": "#D58DFF",
    "no pinky on drinky": "#D58DFF",
    "floor is lava": "#FF3333",
    "marco polo": "#99CCFF"
}

function getTextColor(rule){
    return colorOne[rule.toLowerCase()] || "#FFFF66"
}

function getColorOne(rule){
    return colorOne[rule.toLowerCase()] || "#FFFF66"
}

function getColorTwo(rule){
    return colorTwo[rule.toLowerCase()] || "#FF9933"
}

const GameHeader = ({ lobby }) => {
    const [ruleEventText, setRuleEventText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current index
    const timeoutRef = useRef([]);

    const clearTimeouts = () => {
        timeoutRef.current.forEach((timeout) => clearTimeout(timeout));
        timeoutRef.current = [];
    };

    const renderRuleEvent = (i, msgDiv) => {
        clearTimeouts();

        if (Object.keys(lobby.ruleEvent).length === 0) {
            return;
        }

        timeoutRef.current.push(
            setTimeout(() => {
                if (!msgDiv) {
                    msgDiv = document.querySelector(".ruleEventText");
                }
                if (msgDiv) {
                    msgDiv.style.color = getTextColor(lobby.ruleEvent['rule']);
                    setRuleEventText(
                        i % 2 === 1
                            ? `${lobby.ruleEvent['expires'] - lobby.turnNum} turns left`
                            : lobby.ruleEvent['rule']
                    );
                    setCurrentIndex(i % 2); // Update the index
                }
            }, 750)
        );

        timeoutRef.current.push(
            setTimeout(() => {
                if (!msgDiv) {
                    msgDiv = document.querySelector(".ruleEventText");
                }
                if (msgDiv) {
                    msgDiv.style.color = "black";
                }
                renderRuleEvent(i + 1, msgDiv);
            }, 3500)
        );
    };

    useEffect(() => {
        if (lobby['ruleEvent']) {
            renderRuleEvent(currentIndex, null); // Use the current index to maintain text
        }

        return () => {
            clearTimeouts();
        };
    }, [lobby['ruleEvent']]);


    return (
        <div className='gameHeader'>
            {Object.keys(lobby.ruleEvent).length === 0 ? null : (
                <>
                <div className='ruleEvent'  style={{"--color1":getColorOne(lobby.ruleEvent['rule']), "--color2":getColorTwo(lobby.ruleEvent['rule'])}}>
                    <div className="ruleEventText" style={{color: `${getTextColor(lobby.ruleEvent['rule'])} !important`}}>
                        <span>
                        {ruleEventText}
                        </span>
                    </div>
                </div>
                </>
            )}
            <div className="joinSign">
                <img
                    className='joinCodeOverlay'
                    src={"/gameAssets/join-naut.png"}
                    alt={"joinCode"}
                />
                <span className="joinCodeText">
                    {lobby.roomCode}
                </span>
            </div>
        </div>
    );
};

export default GameHeader;
