import styled from "styled-components";
import React from 'react';


import PromptChallenge from './Challenge/PromptChallenge.js';
import ResponseChallenge from './Challenge/ResponseChallenge.js';
import ChoiceChallenge from './Challenge/ChoiceChallenge.js';
import TimedChallenge from './Challenge/TimedChallenge.js';
import FullscreenChallenge from "./Challenge/FullscreenChallenge.js";
import MinesChallenge from "./Challenge/MinesChallenge.js";
import ReactionChallenge from "./Challenge/ReactionChallenge.js";
import RocketChallenge from "./Challenge/RocketChallenge.js";
import ShipsChallenge from "./Challenge/ShipsChallenge.js";
import WheelChallenge from "./Challenge/WheelChallenge.js"
import IspyChallenge from "./Challenge/IspyChallenge.js"
import SortingChallenge from "./Challenge/SortingChallenge.js"
import MoviesChallenge from "./Challenge/MoviesChallenge.js"
import QuipChallenge from "./Challenge/QuipChallenge.js";
import QuizChallenge from "./Challenge/QuizChallenge.js";
import PokerChallenge from "./Challenge/PokerChallenge.js";
import WordleChallenge from "./Challenge/WordleChallenge.js";
import ColorChallenge from "./Challenge/ColorChallenge.js";
import CoinChallenge from "./Challenge/CoinChallenge.js";
import HeroChallenge from "./Challenge/HeroChallenge.js";
import RouletteChallenge from "./Challenge/RouletteChallenge.js";
import SpinChallenge from "./Challenge/SpinChallenge.js";

//Inactive:
import PoplockChallenge from "./Challenge/PoplockChallenge.js";


const Page = styled.div`
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  backface-visibility: hidden;
  position: absolute;
`;

/*
*   Helpers
*/

function ChallengeGame(props) {  
    const playerColors =[
      "#00FFFF",
      "#FF0000",
      "#FFFF00",
      "#99FF00",
      "#339933",
      "#FF9450",
      "#990050",
      "#FF9900",
      "#9966FF",
      "#FFFFFF",
      "#FF99C3",
      "#0066CC"
  ]

  function getTurnPlayerColor(){
    const tp = props.players.find(u => u.uid === props.turnPlayer)
    return playerColors[tp.iconId]
  }
    
    const renderTwister = () => {
      return (
        <PromptChallenge
          title={props.tileData["selectionTitle"]}
          prompt={props.challengeJson["varData"]}
          yourId={props.yourId}
          rules = {props.tileData['selectionRules']}
          turnPlayer={props.turnPlayer}
          flipped={props.flipped}
          handleClose={props.handleClose}
          turnPlayerName={props.turnPlayerName}
          playerColor = {getTurnPlayerColor()}
        />
      );
    };
  
    const renderCaptcha = () => {
      let answer = props.challengeJson["varData"]
      let captcha = <Image src={`./gameAssets/captcha/${answer}.png`}/>
        return (
        <ResponseChallenge
            title={props.title}
            prompt={props.tileData['selectionRules']}
            mainContent = {captcha}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            flipped = {props.flipped}
            challengeJson = {props.challengeJson}
            sendEvent = {props.sendEvent}
        />
        );
    };

    const renderTrivia = () => {
      let {prompt,answer,choices} = props.challengeJson["varData"];
      prompt = `<name>, ${props.challengeJson["varData"]['prompt']}`;
        return (
        <ChoiceChallenge
            title={props.title}
            prompt={prompt}
            choices ={choices}
            answer = {answer}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            flipped = {props.flipped}
            challengeJson = {props.challengeJson}
            sendEvent = {props.sendEvent}
            turnPlayerName = {props.turnPlayerName}
            playerColor = {getTurnPlayerColor()}
        />
        );
    };

    const renderTimed = () => {
    
      let prompt = props.tileData['selectionRules']
      let highlightedPrompt = null
      if(props.challengeJson.lightningPrompt){
        prompt = `Name 6 ${props.challengeJson["varData"]}`
      }
      if(props.challengeJson.winDrinksPrompt){
        prompt = `List: ${props.challengeJson["varData"]}`
      }

      let afterPrompt = (props.challengeJson.afterPrompt ?
         props.challengeJson.afterPrompt:
         props.tileData['selectionRules'])
      if(props.challengeJson.charadesPrompt){
        prompt = (props.yourId === props.turnPlayer) ? `Phrase to act out:\n${props.challengeJson["varData"]}`: `Guess what ${props.turnPlayerName} is acting`
        afterPrompt = `If no one got it, ${props.turnPlayerName} takes a drink`
      }
      return (
      <TimedChallenge
          title={props.title}
          rules={props.tileData['selectionRules']}
          prompt={prompt}
          highlightedPrompt ={highlightedPrompt}
          yourId = {props.yourId}
          turnPlayer = {props.turnPlayer}
          turnPlayerName = {props.turnPlayerName}
          flipped = {props.flipped}
          challengeJson = {props.challengeJson}
          handleClose={props.handleClose}
          seconds={props.challengeJson['seconds']}
          afterPrompt = {afterPrompt}
          playerColor = {getTurnPlayerColor()}
      />
      );
    };

    const renderFullscreen= () => {
      let fenList = props.challengeJson["varData"];
      return (
        <FullscreenChallenge
            title={props.title}
            fenList = {fenList}
            prompt={props.tileData['selectionRules']}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            flipped = {props.flipped}
            challengeJson = {props.challengeJson}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
        />
      );
    }

    const renderMines= () => {
      let bombLocations = props.challengeJson["varData"];
      return (
        <MinesChallenge
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            bombLocations = {bombLocations}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            flipped={props.flipped}
        />
      );
    }

    const renderReaction= () => {
      return (
        <ReactionChallenge
            title={props.title}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            handleNextBtn = {props.handleNextBtn}
            flipped={props.flipped}
        />
      );

    }

    const renderShips= () => {
      return (
        <ShipsChallenge
            title={props.title}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            handleNextBtn = {props.handleNextBtn}
            players = {props.players}
            flipped={props.flipped}
        />
      );

    }

    const renderRocket= () => {
      const crashNum = props.challengeJson['crashNum']
      return (
        <RocketChallenge
            title={props.title}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            crashNum = {crashNum}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            handleNextBtn = {props.handleNextBtn}
            players = {props.players}
            flipped={props.flipped}
        />
      );
    }

    const renderWheel= () => {
      const spinNum = props.challengeJson['spinNum']
      const randomOffset = props.challengeJson['randomOffset']
      const randomAngle = props.challengeJson['randomAngle']
      return (
        <WheelChallenge
            title={props.title}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            spinNum = {spinNum}
            randomOffset = {randomOffset}
            randomAngle = {randomAngle}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            handleNextBtn = {props.handleNextBtn}
            players = {props.players}
            flipped={props.flipped}
            sLvl = {props.sLvl}
            dLvl = {props.dLvl}
        />
      );
    }

    const renderIspy= () => {
      return (
        <IspyChallenge
            title={props.title}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            handleNextBtn = {props.handleNextBtn}
            players = {props.players}
            flipped={props.flipped}
            challengeJson = {props.challengeJson}
        />
      );

    }

    const renderSorting = () => {
        let prompt = `<name>, ${props.challengeJson["varData"]['prompt']}`;
        return (
        <SortingChallenge
            title={props.title}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            turnPlayerName = {props.turnPlayerName} 
            challengeJson = {props.challengeJson}
            sendEvent = {props.sendEvent}
            varData = {props.challengeJson['varData']}
            prompt = {prompt}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            playerColor = {getTurnPlayerColor()}
        />
        );
    };

    const renderMovies= () => {
      let movieData = props.challengeJson["varData"];
      let prompt = `${props.turnPlayerName} ${props.challengeJson["varData"]['prompt']}`;
      return (
        <MoviesChallenge
            title={props.tileData["selectionTitle"]}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            movieData = {movieData}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            prompt = {prompt}
            flipped={props.flipped}
        />
      );
    }

    const renderQuip = () => {
        console.log(props.players)
        return (
          <QuipChallenge
              title={props.tileData["selectionTitle"]}
              turnPlayerName = {props.turnPlayerName}
              yourId = {props.yourId}
              turnPlayer = {props.turnPlayer}
              sendEvent = {props.sendEvent}
              socketData = {props.socketData}
              handleClose={props.handleClose}
              flipped={props.flipped}
              players = {props.players}
              varData = {props.tileData.varData}
          />
        );

    }

    const renderQuiz = () =>{
      return (
        <QuizChallenge
            title={props.tileData["selectionTitle"]}
            turnPlayerName = {props.turnPlayerName}
            yourId = {props.yourId}
            turnPlayer = {props.turnPlayer}
            sendEvent = {props.sendEvent}
            socketData = {props.socketData}
            handleClose={props.handleClose}
            flipped={props.flipped}
            players = {props.players}
            varData = {props.tileData.varData}
            playerColor = {getTurnPlayerColor()}
        />
      );
    }

    const renderPoker = () =>{
      return (
        <PokerChallenge
          title={props.title}
          turnPlayerName = {props.turnPlayerName}
          yourId = {props.yourId}
          varData = {props.tileData.varData}
          turnPlayer = {props.turnPlayer}
          sendEvent = {props.sendEvent}
          socketData = {props.socketData}
          handleClose={props.handleClose}
          handleNextBtn = {props.handleNextBtn}
          players = {props.players}
          flipped={props.flipped}
        />
      );
    }

    const renderRoulette = () =>{
      return (
        <RouletteChallenge
          title={props.title}
          turnPlayerName = {props.turnPlayerName}
          yourId = {props.yourId}
          varData = {props.tileData.varData}
          turnPlayer = {props.turnPlayer}
          sendEvent = {props.sendEvent}
          socketData = {props.socketData}
          handleClose={props.handleClose}
          handleNextBtn = {props.handleNextBtn}
          players = {props.players}
          flipped={props.flipped}
          order = {props.challengeJson.order}
        />
      );
    }

    const renderPoplock = () =>{
      return <PoplockChallenge
        title={props.title}
        turnPlayerName = {props.turnPlayerName}
        yourId = {props.yourId}
        turnPlayer = {props.turnPlayer}
        sendEvent = {props.sendEvent}
        socketData = {props.socketData}
        handleClose={props.handleClose}
        handleNextBtn = {props.handleNextBtn}
        flipped={props.flipped}
      />
    }

    const renderWordle = () => {
      return <WordleChallenge
      title={props.title}
      turnPlayerName = {props.turnPlayerName}
      yourId = {props.yourId}
      turnPlayer = {props.turnPlayer}
      sendEvent = {props.sendEvent}
      socketData = {props.socketData}
      handleClose={props.handleClose}
      handleNextBtn = {props.handleNextBtn}
      flipped={props.flipped}
      targetWord={props.challengeJson.targetWord}
    />
    }

    const renderColor = () => {
      return <ColorChallenge
      title={props.title}
      turnPlayerName = {props.turnPlayerName}
      yourId = {props.yourId}
      turnPlayer = {props.turnPlayer}
      sendEvent = {props.sendEvent}
      socketData = {props.socketData}
      handleClose={props.handleClose}
      handleNextBtn = {props.handleNextBtn}
      flipped={props.flipped}
      targetHex={props.challengeJson.targetHex}
    />
    }

    const renderHero = () => {
      return <HeroChallenge
      title={props.title}
      turnPlayerName = {props.turnPlayerName}
      yourId = {props.yourId}
      turnPlayer = {props.turnPlayer}
      sendEvent = {props.sendEvent}
      socketData = {props.socketData}
      handleClose={props.handleClose}
      handleNextBtn = {props.handleNextBtn}
      flipped={props.flipped}
      iconGrid={props.challengeJson.iconGrid}
    />
    }

    const renderCoin = () => {
      const iconId = props.players.find(u => u.uid === props.turnPlayer).iconId
      return <CoinChallenge
      title={props.title}
      turnPlayerName = {props.turnPlayerName}
      yourId = {props.yourId}
      iconId = {iconId}
      turnPlayer = {props.turnPlayer}
      sendEvent = {props.sendEvent}
      socketData = {props.socketData}
      handleClose={props.handleClose}
      handleNextBtn = {props.handleNextBtn}
      flipped={props.flipped}
      coinFlip={props.challengeJson.coinFlip}
    />
    }

    const renderSpin = () => {
      return <SpinChallenge
      title={props.title}
      turnPlayerName = {props.turnPlayerName}
      yourId = {props.yourId}
      turnPlayer = {props.turnPlayer}
      sendEvent = {props.sendEvent}
      socketData = {props.socketData}
      handleClose={props.handleClose}
    />
    }
  
    switch (props.challengeJson["style"]) {
      case "response":
        return renderCaptcha();
      case "prompt":
        return renderTwister();
      case "choice":
        return renderTrivia();
      case "timed":
        return renderTimed();
      case "fullscreen":
        return renderFullscreen();
      case "mines":
        return renderMines();
      case "reaction":
        return renderReaction();
      case "rocket":
        return renderRocket();
      case "ships":
        return renderShips();
      case "wheel":
        return renderWheel();
      case "ispy":
        return renderIspy();
      case "sorting":
        return renderSorting();
      case "movies":
        return renderMovies();
      case "quip":
        return renderQuip();
      case "quiz":
        return renderQuiz();
      case "poker":
        return renderPoker();
      case "poplock":
        return renderPoplock();
      case "wordle":
        return renderWordle();
      case "color":
        return renderColor();
      case "coin":
        return renderCoin();
      case "hero":
        return renderHero();
      case "roulette":
        return renderRoulette();
      case "spin":
        return renderSpin()
      default:
        return null;
    }
  }

  export default ChallengeGame;
