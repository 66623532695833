import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DrEvent from "../../display/DrEvent";
import NavButton from '../NavButton.js'

function SpinChallenge(props) {
    const [spinValue, setSpinValue] = useState(null);
    useEffect(() => {
        if (props.socketData.type ==="chal update") {
            if(props.socketData.userInput){
                setSpinValue(props.socketData.userInput)
            }
        }
        }, [props.socketData]);
        function getSpinRotation() {
            // Generate a random integer between 6 and 8 (inclusive) for the number of spins
            const spins = Math.floor(Math.random() * (8 - 6 + 1)) + 6;
        
            // Convert spins to degrees
            const rotation = spins * 360;

            const additionalRotation = Math.floor(Math.random() * 300) + 1;
        
            return rotation + additionalRotation;
        }

        function handleSpinClicked(e){
            e.preventDefault();

            let payload = {};
            payload['userInput'] = getSpinRotation();
            payload['type'] = "chal update"
            props.sendEvent("chal action", payload)
        }

        function renderButtons(){
            if(props.yourId===props.turnPlayer){
                if(spinValue){
                    return (<NavButton delay={30} text={"END TURN"} action={props.handleClose}/>)
                } else {
                    return (<button className='splashNavBtn' onClick={handleSpinClicked}>
                             SPIN
                    </button>)
                }
            }
        }

  return (
    <DrEvent className="challengeContainer"
    style={{ transform: props.flipped ? 'rotateY(180deg)' : '' }}>

    <img src={`/gameAssets/bottle.png`}
        className='spinBottleImage' 
        style={{transform: spinValue ? `rotate(${spinValue}deg)` : 'none'}}                 
    />
    {renderButtons()}
    </DrEvent>
  );
}

export default SpinChallenge;
